<template>
  <div>
    <v-dialog v-model="showCompleteCsvLink" max-width="650">
      <v-card>
        <v-card-title class="justify-space-between">
          <p class="title font-weight-light">CSV Link</p>
          <v-btn icon class="mt-n4" @click="closeCompleteCsvDialog()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="align-center justify-center mb-n5">
            <v-col cols="12" class="mt-n8">
              <v-textarea v-model="dynamicCsvLink" auto-grow></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-banner single-line class="title mb-5" @click:icon="back">
      <v-icon slot="icon" class="pointer-on-hover" color="red"> mdi-chevron-left</v-icon>
      Process DMM Mailing Order

      <template v-if="!isParamsDefined" v-slot:actions>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense clearable class="mr-2"></v-text-field>
      </template>
    </v-banner>

    <v-row v-if="!isParamsDefined">
      <v-col cols="12">
        <tool-table :search="debouncedSearch" />
      </v-col>
    </v-row>
    <v-row v-else class="justify-center py-2 mx-0">
      <v-col cols="12">
        <v-card>
          <v-row class="justify-space-between px-5 py-2">
            <h4 class="title font-weight-regular pl-1 mt-1">Query parameters</h4>
            <v-btn v-if="!isTargeted" color="primary" :disabled="!isCsvLinkContainsNull" @click="rebuildCsvLink">Rebuild CSV link</v-btn>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row class="px-3">
            <v-col cols="12" md="6" class="mb-n2">
              <p class="subheading font-weight-medium grey--text text--darken-2">
                Osprey Order ID:
                <span class="subheading font-weight-light grey--text text--darken-2">{{ crmOrderId }}</span>
              </p>
              <p class="subheading font-weight-medium grey--text text--darken-2">
                Osprey Customer ID:
                <span class="subheading font-weight-light grey--text text--darken-2">{{ crmCustomerId }}</span>
              </p>
              <template v-if="!isTargeted">
                <p v-if="!isFirebaseMapIdZero" class="subheading font-weight-medium grey--text text--darken-2">
                  Map ID:
                  <a v-bind:href="mapIDUrl" target="_blank">map/{{ firestoreMapId }}</a>
                </p>
                <v-row v-else align="center" justify="start" class="pt-0 pb-2">
                  <div style="width: 80px" class="ml-3">
                    <p class="subheading font-weight-medium grey--text text--darken-2 mb-2">Map ID:</p>
                  </div>
                  <div style="width: 250px">
                    <v-text-field v-model="customMapId" label="Enter Firebase Map ID" dense></v-text-field>
                  </div>
                  <div class="mb-2">
                    <v-btn color="red darken-2 white--text" outlined small class="ml-4 mt-n3" @click="checkMapID()">Check Map ID</v-btn>
                  </div>
                </v-row>
              </template>
              <p class="subheading font-weight-medium grey--text text--darken-2">
                Date:
                <span class="subheading font-weight-light grey--text text--darken-2">{{ startDate }} - {{ endDate }}</span>
              </p>
            </v-col>
            <v-col cols="12" md="6" class="mb-n2">
              <p class="subheading font-weight-medium grey--text text--darken-2">
                DMM Campaign ID:
                <span class="subheading font-weight-light grey--text text--darken-2">{{ dmmCampaignId }}</span>
              </p>
              <p class="subheading font-weight-medium grey--text text--darken-2">
                Mail Drop ID:
                <span class="subheading font-weight-light grey--text text--darken-2">{{ crmMailOrderID }}</span>
              </p>
              <p class="subheading font-weight-medium grey--text text--darken-2">
                Mail Drop #:
                <span class="subheading font-weight-light grey--text text--darken-2">{{ dropNum }}</span>
              </p>
              <v-row v-if="!isTargeted">
                <v-col cols="4">
                  <p class="subheading font-weight-medium grey--text text--darken-2 mb-0">
                    Budget Limit:
                    <span v-if="moversLimit > 0" class="subheading font-weight-light grey--text text--darken-2">{{ moversLimit }}</span>
                    <span v-else class="subheading font-weight-light grey--text text--darken-2"> - </span>
                  </p>
                </v-col>
                <v-col cols="8">
                  <v-switch v-if="moversLimit > 0" v-model="isLimitApplied" class="mt-0 pt-0" color="cyan darken-2" label="Apply Limit"></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="!isTargeted">
            <v-col cols="11">
              <p class="subheading font-weight-medium grey--text text--darken-2 mt-n5 mb-0 ml-3 textWrap">
                CSV Link:
                <a v-if="csvLink" :href="dynamicCsvLink" target="_blank" class="subheading font-weight-light grey--text text--darken-2 link-wrapper">{{ dynamicCsvLink }}</a>
              </p>
            </v-col>
            <v-col cols="1" class="mt-n4 mb-n1 text-center">
              <v-tooltip bottom class="justify-end">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mt-n4" @click="openCompleteCsvDialog()">
                    <v-icon medium color="primary" v-on="on">mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                </template>
                <span>Show complete CSV Link</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <dmm-v3
      v-if="isDmmToolInitialized"
      :firestoreMapId="firestoreMapId"
      :orderId="crmOrderId"
      :startDate="startDate"
      :endDate="endDate"
      :csvLinkProp="csvLink"
      :mailDropId="crmMailOrderID"
      :orderData="orderData"
      :dropNr="dropNum"
      :campaignId="dmmCampaignId"
      :isRetry="isRetryRequested"
      :testSiteIds="testSiteIds"
      :isLimitApplied="isLimitApplied"
      :customerId="crmCustomerId"
    >
    </dmm-v3>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DmmV3Process from './tools/DmmV3Process.vue';
import DmmPostcardTable from './dmm/DmmPostcardTable.vue';
import mapsService from '../../services/mapsService';

export default {
  components: { 'dmm-v3': DmmV3Process, 'tool-table': DmmPostcardTable },
  data() {
    return {
      startDate: '',
      endDate: '',
      mapIDUrl: '',
      crmMailOrderID: '',
      crmOrderId: '',
      dmmCampaignId: '',
      dropNum: '',
      firestoreMapId: '',
      csvLink: '',
      orderData: '',
      crmCustomerId: '',
      isParamsDefined: false,
      isLiveEnv: true,
      isCsvLinkContainsNull: false,
      mapResponse: null,
      isFirebaseMapIdZero: false,
      customMapId: '',
      moversLimit: 0,
      showCompleteCsvLink: false,
      dmmOspreyCustomerIds: ['85639', '83190', '89474'],
      isRetryRequested: false,
      isLimitApplied: true,
      isDmmToolInitialized: false,
      search: '',
      debouncedSearch: ''
    };
  },
  computed: {
    env() {
      return this.isLiveEnv ? 'LIVE' : 'TEST';
    },
    isTargeted() {
      return this.orderData.order_type === 'targeted';
    },
    ...mapGetters('customer_messaging', {
      dmmCustomer: 'getCustomer'
    }),
    ...mapGetters('site', {
      testSiteIds: 'getTestSiteIds'
    }),
    dynamicCsvLink() {
      return this.csvLink ? `${this.csvLink}&startDate=${this.startDate}&endDate=${this.endDate}` : '';
    },
    routeQuery() {
      return this.$route.query;
    }
  },
  watch: {
    isLimitApplied(value) {
      if (this.csvLink && this.moversLimit) {
        if (!value) {
          this.csvLink = this.csvLink.replace(`&limit=${this.moversLimit}`, '');
        } else {
          this.csvLink += `&limit=${this.moversLimit}`;
        }
      }
    },
    routeQuery: {
      async handler(parameters) {
        console.log('[ Query params ]:', parameters);

        if (!Object.keys(parameters).length) {
          this.isParamsDefined = false;
          this.isDmmToolInitialized = false;
          return;
        }
        if (!parameters.crm_order_id && !parameters.firebase_order_id) {
          this.showAlert('error', 'Missing Osprey Order ID or Firebase Order ID!');
          return;
        }
        this.isParamsDefined = true;
        this.getOrderDetails(parameters.firebase_order_id ?? parameters.crm_order_id);
        this.crmOrderId = parameters.crm_order_id;
        this.crmMailOrderID = parameters.crm_mail_drop_id;
        this.dmmCampaignId = parameters.hasOwnProperty('dmm_campaign_id') ? parseInt(parameters.dmm_campaign_id) : 0;
        this.dropNum = parameters.drop_num || (await this.getDropPosition(parameters.crm_order_id, parameters.crm_mail_drop_id));
        this.startDate = parameters.start_date;
        this.endDate = parameters.end_date;
        this.crmCustomerId = parameters.crm_customer_id;
        this.firestoreMapId = parameters.firestore_map_id;
        this.isFirebaseMapIdZero = parameters.firestore_map_id === '0';
        this.isRetryRequested = parameters.hasOwnProperty('is_retry') && parseInt(parameters.is_retry) === 1;
      },
      immediate: true
    },
    search(newVal) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.debouncedSearch = newVal;
      }, 500);
    }
  },
  async created() {
    await this.fetchActiveCustomer();
  },
  methods: {
    async fetchActiveCustomer() {
      const customer = await this.$store.dispatch('customer_messaging/fetchActiveCustomer');
      if (customer.status === 'error') {
        this.showAlert('error', 'Customer email error! Please check that just one customer is ON!');
      }
    },
    async getCsvLinkFromMap(firestoreMapId) {
      this.$store.dispatch('showLoader', { message: 'Getting CSV link, please stand by...' });

      try {
        const response = await mapsService.getMapCsvLink(firestoreMapId);
        if (response.status === 'success') {
          console.log('getMapCsvLink:', response);
          this.mapResponse = response;
          this.csvLink = response.csvLink;
          if (this.csvLink.includes('null')) {
            this.isCsvLinkContainsNull = true;
            this.showAlert('warning', 'CSV Link contains null(s) and needs to be rebuild. Please click REBUILD CSV LINK.');
          }
          if (this.moversLimit > 0 && this.csvLink) {
            this.csvLink += `&limit=${this.moversLimit}`;
          }
        } else {
          this.showAlert('error', 'CSV Link not found');
        }
      } catch (error) {
        console.log('Getting CSV link from map has failed:', error);
        this.showAlert('error', 'Getting CSV Link from Map Object failed!');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async getAssetsByOrderId() {
      this.orderData.assets = await this.$store.dispatch('getOrderDataItemByOrderId', {
        firebase_order_id: this.orderData?.firebase_order_id,
        order_data_item: 'assets'
      });
    },
    async getMailingByOrderId() {
      this.orderData.mailing = await this.$store.dispatch('getOrderDataItemByOrderId', {
        firebase_order_id: this.orderData?.firebase_order_id,
        order_data_item: 'mailing'
      });
    },
    async getOrderDetails(orderId) {
      this.$store.dispatch('showLoader', { message: 'Getting order details, please wait...' });
      try {
        this.orderData = isNaN(Number(orderId))
          ? await this.$store.dispatch('getOrderByFirebaseId', { id: orderId })
          : await this.$store.dispatch('getOrderByOspreyID', { ospreyOrderID: orderId });
        const testSiteIdList = this.testSiteIds.length ? this.testSiteIds : await this.$store.dispatch('site/getTestSites');

        this.isLiveEnv = !testSiteIdList.includes(this.orderData.site_db_id);
        this.moversLimit = this.orderData.monthly_qty || 0;
        await Promise.all([this.getAssetsByOrderId(), this.getMailingByOrderId()]);
        if (!this.isFirebaseMapIdZero) {
          await this.getCsvLinkFromMap(this.firestoreMapId);
        }
        this.isDmmToolInitialized = true;
      } catch (error) {
        console.error('[getOrderDetails error ]:', error);
        this.showAlert('error', 'Getting order details failed');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },

    async rebuildCsvLink() {
      this.$store.dispatch('showLoader', { message: 'Processing, please stand by...' });
      try {
        const response = await this.$store.dispatch('maps/getZipList', {
          downloadUrl: this.mapResponse.uploadedFileUrl,
          type: 'movers',
          isUrlFromAmazon: this.mapResponse.uploadedFileUrl.includes('amazon'),
          fileParsing: 'server',
          carrierRoutesOnly: true
        });
        console.log('[ Generate ZipList response ]:', response);
        if (response && response.data && response.data.hasOwnProperty('zipcrids') && response.data.zipcrids) {
          this.isCsvLinkContainsNull = false;
          this.csvLink = `https://emapdata.adc4gis.com/movers.csv?ids=${response.data.zipcrids.toString()}`;
          this.showAlert('success', 'CSV LINK has been successfully rebuilt!');
        }
      } catch (error) {
        console.error('Rebuilding CSV link failed:', error);
        this.showAlert('error', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    async checkMapID() {
      console.log('[ CustomMapId ]:', this.customMapId);
      if (!this.customMapId.length) {
        this.showAlert('error', 'Firebase Map ID field is empty, please add a Firebase map ID and try again!');
        return;
      }
      this.$store.dispatch('showLoader', { message: 'Getting CSV link, please stand by...' });
      try {
        const response = await mapsService.getMapCsvLink(this.customMapId);
        if (response.status === 'success') {
          console.log('getMapCsvLink:', response);
          this.mapResponse = response;
          this.csvLink = response.csvLink;
          if (this.csvLink.includes('null')) {
            this.isCsvLinkContainsNull = true;
            this.showAlert('warning', 'CSV Link contains null(s) and needs to be rebuild. Please click REBUILD CSV LINK.');
          }
        } else {
          this.showAlert('error', 'Csv Link not found');
        }
      } catch (error) {
        console.error('[ checkMapId error ]:', error);
        this.showAlert('error', 'An error occured while checking map id. Check console for more details.');
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    openCompleteCsvDialog() {
      this.showCompleteCsvLink = true;
    },
    closeCompleteCsvDialog() {
      this.showCompleteCsvLink = false;
    },
    back() {
      this.$router.back();
    },
    async getDropPosition(orderID, mailOrderID) {
      const response = await this.$store.dispatch('postcards/getDropsByOrderId', {
        orderID
      });
      const dropPosition = response.data.findIndex(drop => drop.mailOrderID == mailOrderID);
      return dropPosition + 1;
    }
  }
};
</script>

<style scoped>
.textWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a {
  text-decoration: none;
}
.pointer-on-hover {
  cursor: pointer;
}
</style>
