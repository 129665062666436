<template>
  <v-container>
    <v-row class="mt-n4 mb-4">
      <v-toolbar flat class="site-table--header">
        <v-toolbar-title class="body-2 mr-2">Status</v-toolbar-title>
        <v-chip-group v-model="selectedStatus" active-class="primary--text" mandatory>
          <template v-for="(filterName, index) in statusFilterItems">
            <v-chip v-if="true" :key="index" filter outlined>
              <span>{{ filterName }}</span>
            </v-chip>
          </template>
        </v-chip-group>
      </v-toolbar>
    </v-row>
    <stamp-data-table
      ref="dataTable"
      :headers="headers"
      :data="drops"
      item-key="mailOrderID"
      :search="search"
      sort-by="EstimatedMailDate"
      must-sort
      :is-loading="isFouroverOrdersLoading"
      :total-server-items-nr="totalItems"
      @update:options="getFourOverOrders"
    >
      <template #[`item.EstimatedMailDate`]="{ item }">
        {{ formatDate(item.EstimatedMailDate) }}
      </template>
      <template #[`item.ActualMailDate`]="{ item }">
        {{ formatDate(item.ActualMailDate) }}
      </template>
      <template #[`item.Order.oPrintPrice`]="{ item }">
        {{ formatDollar(item.Order.oPrintPrice) }}
      </template>
      <template #[`item.fourover_payment_amount`]="{ item }">
        {{ formatDollar(item.fourover_payment_amount) }}
      </template>
      <template #[`item.Order.firebase_order_id`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="copyItemToClipboard(item.firebase_order_id)"><v-icon color="amber darken-3">mdi-firebase</v-icon></v-btn>
          </template>
          <span>Copy FirebaseID to clipboard [ {{ item.firebase_order_id }} ]</span>
        </v-tooltip>
      </template>
      <template #[`item.action`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon color="blue darken-1" @click="openItem(item)" v-on="on">mdi-arrow-right-bold-circle-outline</v-icon>
          </template>
          <span>{{ actionButtonText }}</span>
        </v-tooltip>
      </template>
    </stamp-data-table>
  </v-container>
</template>
<script>
import DataTable from '../../common/DataTable.vue';

export default {
  components: { 'stamp-data-table': DataTable },
  props: ['search'],
  data() {
    return {
      fourOverOrders: [],
      drops: [],
      headers: [
        { text: 'Drop ID', value: 'mailOrderID', sortable: true },
        { text: 'Scheduled Date', value: 'EstimatedMailDate', sortable: true, align: 'center' },
        { text: 'Actual Date', value: 'ActualMailDate', sortable: true, align: 'center' },
        { text: 'Quantity', value: 'quantity', sortable: true },
        { text: 'Act. Quantity', value: 'actualPieces', sortable: true },
        { text: 'Osprey Order ID', value: 'orderID', sortable: true },
        { text: 'Osprey Customer ID', value: 'Order.CustomerID', sortable: true },
        { text: 'Order Total', value: 'Order.oPrintPrice', sortable: true, align: 'right' },
        { text: '4over Payment', value: 'fourover_payment_amount', sortable: true, align: 'right' },
        { text: '4over Job ID', value: 'fourover_job_id', sortable: true, align: 'center' },
        { text: 'Firebase Order ID', value: 'Order.firebase_order_id', sortable: true, align: 'center' },
        { text: 'Action', value: 'action', align: 'center', sortable: false }
      ],
      statusFilterItems: ['Initial', 'Launched'], //[0, 1]
      selectedStatus: 0,
      isFouroverOrdersLoading: false,
      totalItems: 0,
      itemsPerPage: 10
    };
  },
  computed: {
    actionButtonText() {
      return this.selectedStatus ? 'View Order Details' : 'Send to 4over';
    }
  },
  watch: {
    selectedStatus() {
      this.getFourOverOrders({ ...this.$refs.dataTable.options, searchQuery: '' });
    },
    search: {
      handler(newQuery) {
        this.getFourOverOrders({ ...this.$refs.dataTable.options, searchQuery: newQuery });
      },
      immediate: true
    }
  },

  methods: {
    async getFourOverOrders(options) {
      this.isFouroverOrdersLoading = true;
      const { page, itemsPerPage, sortBy, sortDesc, searchQuery } = options;
      const sortDirection = sortDesc[0] ? 'DESC' : 'ASC';

      try {
        const response = await this.$store.dispatch('fourover/getFourOverOrders', {
          page,
          itemsPerPage,
          sortBy: sortBy[0],
          sortDirection,
          launched: this.selectedStatus,
          search: searchQuery || ''
        });
        const result = response.data;
        this.drops = result.data;
        this.totalItems = result.meta.totalCount;
      } catch (error) {
        console.error('Getting 4over orders error:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'Could not load orders for 4over. Please try again later.' });
      } finally {
        this.isFouroverOrdersLoading = false;
      }
    },
    openItem(drop) {
      const path = this.selectedStatus
        ? `/orders/view/${drop.firebase_order_id}`
        : `?firebase_order_id=${drop.firebase_order_id}&osprey_order_id=${drop.osprey_order_id}&mail_drop_id=${drop.mail_drop_id}&quantity=${drop.qty}`;
      this.$router.push({ path });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    },
    formatDollar(number) {
      return this.$store.getters.getUtilsInstance.formatDollar(number);
    },
    formatDate(date) {
      return date ? this.$moment(date).format('MM/DD/YYYY') : '';
    }
  }
};
</script>
