import MomentTimeZone from 'moment-timezone';

class Utils {
  constructor(context) {
    this.context = context;
  }

  getCurrentDate() {
    return this.context.$moment();
  }

  getFormattedDate(format) {
    return this.context.$moment().format(format);
  }

  getUnix(date) {
    return this.context.$moment(date).format('X');
  }

  getEndOfDay(date) {
    return this.context.$moment(date).endOf('day');
  }

  getISO(date) {
    return this.context.$moment(date).format();
  }

  getESTDate(date) {
    return MomentTimeZone.tz(date, 'America/New_York').format();
  }

  formatDate(date, format) {
    return this.context.$moment(date).format(format);
  }

  addDays(date, numOfDays) {
    return this.context.$moment(date).add(numOfDays, 'days');
  }

  formatDollar(number) {
    const USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
    return number && !isNaN(number) ? USDollar.format(number) : '';
  }
}

export { Utils as default };
