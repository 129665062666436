<template>
  <div :class="{ 'templates-table': isBorderVisible }">
    <confirm-delete-dialog
      :isDialogOpened="isDeleteConfirmationDialogOpened"
      :itemNames="selectedItemKeysToDelete"
      @closeDialog="closeDeleteConfirmationDialog()"
      @confirmDelete="deleteConfirmed()"
    ></confirm-delete-dialog>

    <v-data-table
      v-model="selected"
      :headers="tableHeaders"
      :items="tableItems"
      :disable-items-per-page="isItemsPerPageDisabled"
      :items-per-page="itemsPerPage"
      :server-items-length="totalServerItemsNr"
      :search="search"
      :loading="isLoading"
      :item-key="itemKey"
      :item-class="itemClass"
      :must-sort="mustSort"
      :options.sync="options"
      :footer-props="{
        disableItemsPerPage: isItemsPerPageDisabled
      }"
      :class="`${itemsPerPageSelectorStyle} ${customClass}`"
      :single-select="isSingleSelectEnabled"
      :show-select="isSingleSelectEnabled || isMultiSelectEnabled"
      :expanded="expanded"
      :hide-default-header="isHeaderHidden"
      :hide-default-footer="isFooterHidden"
      loading-text="Loading... Please wait"
      :no-data-text="noDataText"
      @dblclick:row="onRowDoubleClicked"
      @input="selectItem"
      @update:options="updateOptions"
    >
      <template #[`header.trash`]>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn small icon :disabled="isDeleteIconDisabled" class="d-none d-sm-flex" v-on="on" @click="deleteSelectedItems()">
              <v-icon color="primary" class="ml-n1">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Delete Selected Items</span>
        </v-tooltip>
      </template>
      <template #[`item.trash`]="{ item }">
        <v-checkbox v-model="selectedItemsToDelete" :value="item[itemKey]" class="d-none d-sm-flex mb-n2 mt-3"></v-checkbox>
      </template>

      <template v-if="isEditable" #[`item`]="{ item, index }">
        <tr>
          <td v-for="propName in Object.keys(item)" :key="`${index}-${propName}`">
            <v-text-field :key="index" v-model="tableItems[index][propName]" outlined dense class="mb-n3 mt-3"></v-text-field>
          </td>
        </tr>
      </template>

      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>

      <template v-if="actions.length" #[`item.actions`]="{ item }">
        <v-menu bottom left>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(action, actionIndex) in actions" :key="actionIndex" dense @click="emitAction(action, item)">
              <v-list-item-icon>
                <v-icon :color="action.iconColor">{{ action.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ action.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template #[`footer.prepend`]>
        <span v-if="footerLabel" class="ml-3 subtitle-2 font-weight-regular">{{ footerLabel }}</span>
        <v-row v-if="isDataTableItemEdited" class="justify-start ma-2">
          <v-btn color="grey darken-2" outlined small @click="resetTableValues()">Reset</v-btn>
          <v-btn class="ml-3" color="primary" small>Save Changes</v-btn>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ConfirmDeleteDialog from '../dialog/ConfirmDeleteDialog.vue';

export default {
  components: { ConfirmDeleteDialog },
  props: {
    headers: { type: Array, required: true },
    data: { type: Array, required: true },
    totalServerItemsNr: { type: Number, default: -1 },
    itemKey: { type: String, required: true },
    itemClass: { type: Function, default: () => String },
    isItemsPerPageDisabled: { type: Boolean, default: false },
    itemsPerPage: { type: Number, default: 15 },
    currentPage: { type: Number, default: 1 },
    search: { type: String, default: '' },
    sortBy: { type: String, default: '' },
    sortDesc: { type: Boolean, default: false },
    mustSort: { type: Boolean, default: false },
    isBorderVisible: { type: Boolean, default: false },
    isMultipleDeleteEnabled: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
    isEditable: { type: Boolean, default: false },
    isFooterHidden: { type: Boolean, default: false },
    isHeaderHidden: { type: Boolean, default: false },
    isSingleSelectEnabled: { type: Boolean, default: false },
    isMultiSelectEnabled: { type: Boolean, default: false },
    footerLabel: { type: String, default: '' },
    itemPropToDisplayOnDeleteDialog: { type: String },
    customClass: { type: String, default: 'elevation-1' },
    noDataText: { type: String, default: 'No records found' },
    actions: { type: Array, default: () => [] }
  },
  data() {
    return {
      selected: [],
      expanded: [],
      selectedItemsToDelete: [],
      selectedItemKeysToDelete: [],
      isDeleteConfirmationDialogOpened: false,
      isDataTableItemEdited: false,
      tableItems: [],
      options: {
        page: this.currentPage,
        itemsPerPage: this.itemsPerPage,
        sortBy: [this.sortBy],
        sortDesc: [this.sortDesc]
      }
    };
  },
  computed: {
    tableHeaders() {
      const headers = [];
      const isActionsHeaderDefined = this.headers.find(header => header.value === 'actions');
      if (!this.isEditable && this.isMultipleDeleteEnabled) {
        headers.push({ text: '', value: 'trash', sortable: false, width: '1%' });
      }

      headers.push(...this.headers);

      if (!isActionsHeaderDefined && this.actions.length && !this.isEditable) {
        headers.push({ text: 'Actions', value: 'actions', sortable: false, align: 'center', width: '5%' });
      }
      return headers;
    },
    isDeleteIconDisabled() {
      return !this.selectedItemsToDelete.length;
    },
    itemsPerPageSelectorStyle() {
      return this.isItemsPerPageDisabled ? 'stamp-orders-data-table table-footer-text-end' : '';
    }
  },
  watch: {
    data: {
      handler: function () {
        this.setTableItems();
      },
      immediate: true
    },
    tableItems: {
      handler: function (value) {
        this.isDataTableItemEdited = this.checkDataTableEditedRows(value);
      },
      deep: true
    },
    currentPage: {
      handler: function (page) {
        this.options.page = page;
      },
      deep: true
    },
    options(options) {
      this.$emit('options', options);
    },
    selected: {
      handler: function (item) {
        if (Object.values(item).length) {
          this.$emit('selected', item);
        }
      },
      immediate: true
    },
    expanded: {
      handler: function (item) {
        if (Object.values(item).length) {
          this.$emit('expanded', item);
        }
      },
      immediate: true
    }
  },
  methods: {
    setTableItems() {
      this.tableItems = this.data;
      this.$store.commit('tools/setInitialDataTableState', JSON.stringify(this.data));
    },
    selectItem() {
      if (this.isMultiSelectEnabled) {
        this.$emit('onItemSelect', this.selected);
      }
    },
    deleteSelectedItems() {
      const selectedItemNames = [];
      this.selectedItemsToDelete.forEach(selected => {
        const itemNameToDisplay = this.tableItems.find(tableItem => tableItem[this.itemKey] === selected);
        if (itemNameToDisplay) {
          selectedItemNames.push(itemNameToDisplay);
        }
      });
      this.selectedItemKeysToDelete = selectedItemNames.map(item => item[this.itemPropToDisplayOnDeleteDialog]);
      this.isDeleteConfirmationDialogOpened = true;
    },
    closeDeleteConfirmationDialog() {
      this.isDeleteConfirmationDialogOpened = false;
      this.selectedItemsToDelete = [];
    },
    deleteConfirmed() {
      console.log('[ Deleting selected items confirmed ]:', this.selectedItemsToDelete);
      this.$emit('deleteSelectedItems', this.selectedItemsToDelete);
      this.closeDeleteConfirmationDialog();
    },
    emitAction(action, item) {
      this.$emit(action.eventName, item);
    },
    updateOptions(newOptions) {
      this.$emit('update:options', newOptions);
    },
    checkDataTableEditedRows(edited) {
      const initial = this.$store.getters['tools/getInitialDataTableState'];
      return initial !== JSON.stringify(edited);
    },
    resetTableValues() {
      if (this.isEditable) {
        this.tableItems = JSON.parse(this.$store.getters['tools/getInitialDataTableState']);
      }
    },
    saveEditChanges() {
      this.$emit('editChanges', this.tableItems);
    },
    onRowDoubleClicked(event, { item }) {
      this.$emit('doubleClick', item);
    },
    resetSelectedItems() {
      this.selected = [];
    }
  }
};
</script>

<style>
.v-data-table {
  width: 100%;
}

.table-footer-text-end .v-data-footer {
  justify-content: end !important;
}

/* make table cells bordered */
.templates-table td,
.templates-table th {
  border-right: thin solid rgb(0 0 0 / 12%) !important;
  border-bottom: thin solid rgb(0 0 0 / 12%) !important;
}

/* imitate header style*/
.templates-table th {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.6);
}
</style>
