<template>
  <div>
    <v-banner single-line class="title mb-4" @click:icon="back">
      <v-icon slot="icon" color="red" class="showPointerOnHover">mdi-chevron-left</v-icon>
      4over Tool

      <template v-if="!isParamsDefined" v-slot:actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense clearable class="mr-2"></v-text-field>
            <v-btn v-if="isUserSuperAdmin" icon v-on="on" @click="openFouroverStatusMap"><v-icon>mdi-tools</v-icon></v-btn>
          </template>
          <span>Status Map</span>
        </v-tooltip>
      </template>
    </v-banner>
    <v-row v-if="!isParamsDefined">
      <v-col cols="12">
        <four-over-table :search="debouncedSearch" />
      </v-col>
    </v-row>
    <v-row v-else class="justify-center py-2 mx-0">
      <v-col cols="12">
        <v-card>
          <v-row class="px-5 py-2">
            <h4 class="title font-weight-regular pl-1 mt-1">Parameters</h4>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row class="px-3 grey--text text--darken-2">
            <v-col cols="12" md="6" class="mb-n2">
              <p class="font-weight-medium">
                Firebase Order ID:
                <span class="font-weight-light">{{ firebaseOrderId }}</span>
              </p>
              <p class="font-weight-medium">
                Osprey Order ID:
                <span class="font-weight-light">{{ ospreyOrderId }}</span>
              </p>
              <p class="font-weight-medium">
                Mail Drop ID:
                <span class="font-weight-light">{{ mailDropId }}</span>
              </p>
              <p class="font-weight-medium">
                Product Name:
                <span class="font-weight-light">{{ order.orderData.product.name }}</span>
              </p>
            </v-col>
            <v-col cols="12" md="6" class="mb-n2">
              <p class="font-weight-medium">
                Size:
                <span class="font-weight-light">{{ order.orderData.product.size_x }} x {{ order.orderData.product.size_y }}</span>
              </p>
              <p class="font-weight-medium">
                Quantity:
                <span class="font-weight-light">{{ dropQuantity }}</span>
              </p>
              <p class="font-weight-medium">
                Turnaround:
                <span class="font-weight-light">{{ order.orderData.delivery.turnaround }}</span>
              </p>
              <p class="font-weight-medium">
                Shipping Method:
                <span class="font-weight-light">{{ order.orderData.delivery.name }}</span>
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <four-over-process
      v-if="isParamsDefined"
      :dropQuantity="dropQuantity"
      :firebaseOrderId="firebaseOrderId"
      :ospreyOrderId="ospreyOrderId"
      :mailDropId="mailDropId"
      :order="order"
    />
  </div>
</template>
<script>
import FourOverTable from './FourOverTable';
import FourOverProcess from './FourOverProcess.vue';
import orderService from '@/services/orderService.js';
import { mapGetters } from 'vuex';

export default {
  components: { 'four-over-table': FourOverTable, 'four-over-process': FourOverProcess },
  data() {
    return {
      isParamsDefined: false,
      firebaseOrderId: '',
      ospreyOrderId: 0,
      mailDropId: 0,
      dropQuantity: 0,
      order: {},
      is4overToolInitialized: false,
      search: '',
      debouncedSearch: ''
    };
  },
  computed: {
    routeQuery() {
      return this.$route.query;
    },
    ...mapGetters('users', {
      userAccessLevel: 'userAccessLevel'
    }),
    isUserSuperAdmin() {
      return this.userAccessLevel && parseInt(this.userAccessLevel.value) === 100;
    }
  },
  watch: {
    routeQuery: {
      handler(parameters) {
        console.log('[ Query params ]:', parameters);

        if (!Object.keys(parameters).length) {
          this.isParamsDefined = false;
        } else {
          if (!parameters.firebase_order_id) {
            return this.showAlert('error', 'Could not load the tool because the Firebase Order ID is missing!');
          }
          this.getOrderDetails(parameters.firebase_order_id);
          this.firebaseOrderId = parameters.firebase_order_id;
          this.ospreyOrderId = parameters.osprey_order_id;
          this.mailDropId = parameters.mail_drop_id;
          this.dropQuantity = parameters.quantity;
        }
      },
      immediate: true
    },
    search(newVal) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.debouncedSearch = newVal;
      }, 500);
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    showAlert(type, message) {
      this.$store.dispatch('showAlert', { type, message });
    },
    async getOrderDetails(firebaseOrderId) {
      try {
        this.$store.dispatch('showLoader', { message: 'Getting order data, please wait...' });
        this.order = await this.$store.dispatch('getOrderByFirebaseId', { id: firebaseOrderId });
        this.order.orderData = await orderService.getOrderData(firebaseOrderId);

        await this.$store.dispatch('fourover/fetchProductOptions', {
          firebase_order_id: firebaseOrderId,
          firebase_product_id: this.order.orderData.product.firebase_product_id,
          product_origin: this.order.orderData.product.product_data.product_origin || 'local'
        });
        this.isParamsDefined = true;
        this.is4overToolInitialized = true;
      } catch (error) {
        this.showAlert('error', 'Could not load the necessary information to initialize the tool.');
        console.log('[ getOrderDetails ERROR ] :', error);
      } finally {
        this.$store.dispatch('hideLoader');
      }
    },
    openFouroverStatusMap() {
      this.$router.push('/fourover-statuses');
    }
  }
};
</script>

<style scoped>
.showPointerOnHover {
  cursor: pointer;
}
</style>
