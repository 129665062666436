import { cloudFunctionsGet, cloudFunctionsPost, cloudFunctionsPut } from '../../modules/cloud-function-wrapper.js';
import productService from '../../services/productService.js';
import orderService from '../../services/orderService.js';
const errorHandler = require('../../modules/helpers/errorHandler');
import Vue from 'vue';

const state = {
  mainOptionGroups: [],
  additionalOptionGroups: [],
  paymentToken: '',
  productInfo: {},
  artworkData: {}
};

const getters = {
  getMainOptionGroups(state) {
    return state.mainOptionGroups;
  },
  getAdditionalOptionGroups(state) {
    return state.additionalOptionGroups;
  },
  getProductInfo(state) {
    return state.productInfo;
  },
  getArtworkData(state) {
    return state.artworkData;
  },
  getPaymentToken(state) {
    return state.paymentToken;
  }
};

const mutations = {
  setMainOptionGroups(state, payload) {
    state.mainOptionGroups = payload;
  },
  setAdditionalOptionGroups(state, payload) {
    state.additionalOptionGroups = payload;
  },
  setProductInfoField(state, { field, value }) {
    Vue.set(state.productInfo, field, value);
  },
  clearProductInfo(state) {
    state.productInfo = {};
  },
  setArtworkDataField(state, { field, value }) {
    Vue.set(state.artworkData, field, value);
  },
  clearArtworkData(state) {
    state.artworkData = {};
  },
  setPaymentToken(state, payload) {
    state.paymentToken = payload;
  }
};

const actions = {
  clearAll({ commit }) {
    commit('clearProductInfo');
    commit('setMainOptionGroups', []);
    commit('setAdditionalOptionGroups', []);
    commit('clearArtworkData');
  },

  async fetchProductOptions({ commit, dispatch }, payload) {
    try {
      dispatch('clearAll');

      const fourover_product_id =
        payload.product_origin === '4over' ? payload.firebase_product_id : (await productService.getById(payload.firebase_product_id))?.fourover_product_id || '';

      commit('setProductInfoField', { field: 'product_uuid', value: fourover_product_id });
      const { data } = await cloudFunctionsGet(`/fourover/printproducts/products/${fourover_product_id}/optiongroups`);

      const mainOptionGroupMap = [
        { group_name: 'Runsize', field_name: 'runsize_uuid' },
        { group_name: 'Colorspec', field_name: 'colorspec_uuid' },
        { group_name: 'Turn Around Time', field_name: 'turnaround_uuid' }
      ];
      const mainOptionGroups = [];
      mainOptionGroupMap.forEach(group => {
        let [optionGroup] = data.entities
          .filter(({ product_option_group_name }) => product_option_group_name === group.group_name)
          .map(optionGroup => {
            let isTurnaround = ['Turn Around Time', 'Turn Around', 'Turn-Around', 'TurnAround', 'Turnaround'].includes(optionGroup.product_option_group_name);
            return {
              name: isTurnaround ? 'Turnaround Time' : optionGroup.product_option_group_name,
              field_name: group.field_name,
              options: optionGroup.options.map((option, index) => {
                let optionObject = {
                  id: index + 1,
                  text: isTurnaround ? option.option_description : option.option_name,
                  value: option.option_uuid
                };
                if (isTurnaround) {
                  optionObject.runsize = option.runsize;
                  optionObject.runsize_uuid = option.runsize_uuid;
                  optionObject.colorspec = option.colorspec;
                  optionObject.colorspec_uuid = option.colorspec_uuid;
                }
                return optionObject;
              })
            };
          });
        mainOptionGroups.push(optionGroup);
      });
      commit('setMainOptionGroups', mainOptionGroups);

      const additionalOptionGroups = data.entities
        .filter(
          optionGroup =>
            optionGroup.options.length > 1 &&
            !mainOptionGroupMap.map(({ group_name }) => group_name).includes(optionGroup.product_option_group_name) &&
            optionGroup.product_option_group_name !== 'Additional Options'
        )
        .map(optionGroup => ({
          name: optionGroup.product_option_group_name,
          options: optionGroup.options.map(option => ({ text: option.option_name, value: option.option_uuid }))
        }));
      commit('setAdditionalOptionGroups', additionalOptionGroups);
    } catch (error) {
      console.log('fetchProductOptions has failed: ', error);
    }
  },

  async fetchShippingOptions({ commit }, payload) {
    const { data } = await cloudFunctionsPost('/fourover/shippingquote', payload);
    console.log('[fetchShippingOptions] response:', data);
    if (data.ambiguos_address) {
      throw errorHandler.operationError({ operation: 'Fetching Shipping Options', error: { message: 'the address is ambiguous' } });
    }
    return data.job.facilities[0].shipping_options.map(option => ({
      shipping_method: option.service_name,
      shipping_code: option.service_code
    }));
  },

  async getAuthKeys({ commit }) {
    const { data } = await cloudFunctionsGet('/fourover/getkeys');
    return data;
  },

  async createArtwork({ commit, dispatch }, payload) {
    const { data } = await cloudFunctionsPost('/fourover/files', payload.data);
    console.log('[4over Artwork Response]', data);

    const [files] = Object.values(data);
    const [file] = files;
    if (files.length && Object.values(file).length && file.pdf_uuid) {
      commit('setArtworkDataField', { field: payload.meta.side, value: file.pdf_uuid });
      const { box_dimensions, status, message, ...links } = file;
      const keys = await dispatch('getAuthKeys');
      const auth = `?apikey=${keys.publicKey}&signature=${keys.signature}`;
      links.pdf_uri = `${links.pdf_uri}${auth}`;
      links.preview_uri = `${links.preview_uri}${auth}`;
      let artworkData = {};
      if (payload.meta.side === 'fr') {
        artworkData = {
          fourover_front_artwork: links
        };
      } else {
        artworkData = {
          fourover_back_artwork: links
        };
      }

      await orderService.updateOrderData(payload.meta.firebase_order_id, 'delivery', artworkData);
      return artworkData;
    }
  },
  async createOrder({ dispatch }, payload) {
    const { data } = await cloudFunctionsPost('/fourover/orders', payload);
    console.log('[ 4over response: ] ', data);
    const [fouroverJobId] = Object.keys(data?.job_ids);
    const payment = parseFloat(data?.payment_response?.authorized_payment_amount) || 0;
    const fouroverFields = {
      firebase_order_id: payload.order_id,
      mail_drop_id: payload.meta.osprey_mail_order_id,
      job_id: fouroverJobId,
      fourover_payment: payment
    };
    await dispatch('saveFouroverFields', fouroverFields);
    return data;
  },

  async saveFouroverFields({ commit }, payload) {
    await orderService.updateOrderData(payload.firebase_order_id, 'delivery', { fourover_job_id: payload.job_id, fourover_payment: payload.fourover_payment });
    await cloudFunctionsPut(`/mailorders/update/${payload.mail_drop_id}`, { fourover_payment_amount: payload.fourover_payment });
    console.log('[ 4over fields saved ]', payload);
  },

  async getFourOverOrders({ commit }, payload) {
    try {
      return await cloudFunctionsGet(
        `/mailorders/fourover/drops?page=${payload.page}&limit=${payload.itemsPerPage}&sortBy=${payload.sortBy}&sortDirection=${payload.sortDirection}&launched=${payload.launched}&search=${payload.search}`
      );
    } catch (error) {
      console.error('[getFourOverOrders] Error fetching 4over orders:', error);
      throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
