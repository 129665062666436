<template>
  <div>
    <stamp-data-table
      ref="dataTable"
      :headers="headers"
      :data="drops"
      item-key="mailOrderID"
      :search="search"
      sort-by="EstimatedMailDate"
      must-sort
      :isLoading="isPostcardDropsLoading"
      :total-server-items-nr="totalItems"
      @update:options="loadPostcardDrops"
    >
      <template #[`item.EstimatedMailDate`]="{ item }">
        {{ formatDate(item.EstimatedMailDate) }}
      </template>
      <template #[`item.ActualMailDate`]="{ item }">
        {{ formatDate(item.ActualMailDate) }}
      </template>
      <template #[`item.Order.firebase_order_id`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="copyItemToClipboard(item.firebase_order_id)"><v-icon color="amber darken-3">mdi-firebase</v-icon></v-btn>
          </template>
          <span>Copy FirebaseID to clipboard [ {{ item.firebase_order_id }} ]</span>
        </v-tooltip>
      </template>
      <template #[`item.action`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-icon color="blue darken-1" @click="launchCampaign(item)" v-on="on">mdi-arrow-right-bold-circle-outline</v-icon>
          </template>
          <span>Launch Campaign</span>
        </v-tooltip>
      </template>
    </stamp-data-table>
  </div>
</template>
<script>
import DataTable from '../../common/DataTable.vue';
import { mapGetters } from 'vuex';

export default {
  components: { 'stamp-data-table': DataTable },
  props: ['search'],
  data() {
    return {
      drops: [],
      isPostcardDropsLoading: false,
      totalItems: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'Mail Drop ID', value: 'mailOrderID', sortable: true },
        { text: 'Scheduled Date', value: 'EstimatedMailDate', sortable: true, align: 'center' },
        { text: 'Actual Date', value: 'ActualMailDate', sortable: true, align: 'center' },
        { text: 'Quantity', value: 'quantity', sortable: true },
        { text: 'Act. Quantity', value: 'actualPieces', sortable: true },
        { text: 'Size X', value: 'Order.oSizeX', sortable: true },
        { text: 'Size Y', value: 'Order.oSizeY', sortable: true },
        { text: 'Osprey Order ID', value: 'orderID', sortable: true },
        { text: 'Osprey Customer ID', value: 'Order.CustomerID', sortable: true },
        { text: 'Firebase Order ID', value: 'Order.firebase_order_id', sortable: true, align: 'center' },
        { text: 'Action', value: 'action', align: 'center', sortable: false }
      ]
    };
  },
  watch: {
    search: {
      handler(newQuery) {
        this.loadPostcardDrops({ ...this.$refs.dataTable.options, searchQuery: newQuery } || {});
      },
      immediate: true
    }
  },
  methods: {
    async loadPostcardDrops(options) {
      this.isPostcardDropsLoading = true;
      const { page, itemsPerPage, sortBy, sortDesc, searchQuery } = options;
      const sortDirection = sortDesc[0] ? 'DESC' : 'ASC';

      try {
        const response = await this.$store.dispatch('postcards/getTargetedPostcards', {
          page,
          itemsPerPage,
          sortBy: sortBy[0],
          sortDirection,
          search: searchQuery || ''
        });
        const result = response.data;
        this.drops = result.data;
        this.totalItems = result.meta.totalCount;
      } catch (error) {
        console.error('Getting targeted orders error:', error);
        this.$store.dispatch('showAlert', { type: 'error', message: 'Could not load orders for 4over. Please try again later.' });
      } finally {
        this.isPostcardDropsLoading = false;
      }
    },
    launchCampaign(order) {
      const startDate = this.$moment().subtract(1, 'months').format('YYYY-MM-DD');
      const endDate = this.$moment().format('YYYY-MM-DD');
      const queryString = `?start_date=${startDate}&end_date=${endDate}&crm_mail_drop_id=${order.mailOrderID}&crm_order_id=${order.orderID}&crm_customer_id=${order.Order.CustomerID}&firebase_order_id=${order.Order.firebase_order_id}&dmm_campaign_id=0&firestore_map_id=0`;
      this.$router.push({ path: queryString });
    },
    copyItemToClipboard(item) {
      navigator.clipboard.writeText(item);
    },
    formatDate(date) {
      return date ? this.$moment(date).format('MM/DD/YYYY') : '';
    }
  }
};
</script>
